
<div class="wrapper-calendar size">
	<div class="header-calendar">
		<div id="prev" class="button-change">
			<button (click)="changeMonth(-1)" class="btn-prev">
				<img  src="assets/img/arrow_back_ios_black_24dp.svg" />
				</button>
		</div>
		<h1>{{dateSelect | date:'MMMM, yyyy'}}</h1>
		<div id="next" class="button-change">
			<button (click)="changeMonth(1)" class="btn-next">
				<img src="assets/img/arrow_forward_ios_black_24dp.svg" />
			</button>
		</div>
	</div>
	<ol>
		<li *ngFor="let day of week" class="day-name">{{day | slice:0:3}}</li>

        <li (click)="clickDay(day)" [style.gridColumnStart]="first ? day?.indexWeek : 'auto'"
         *ngFor="let day of monthSelect;let first = first">
			 <span  [ngClass]="{'daySelected': day.value == this.daySelected, 'dayNoSelected' : day.value !=this.daySelected}">{{day?.value}}</span>	 
		</li>
	</ol>
</div>

