<div class="modal" id="modal" >
    <div class="modal__window eventeditor" cdkDrag >
       
        <div class="content-height" id="content-height">
            <section cdkDragHandle  class="eventeditor__header">
                <div class="menu-buttons">
                    <div>
                        <a (click)='closeEvent($event)' class="button ghost close-icon">
                            <i class="sprite-event-close" style="background:url('assets/img/close-black.svg')"></i>
                        </a>
                    </div>
                        <ng-content select="[a]"></ng-content>
                        &nbsp;
                </div>
            </section>
            <section class="eventeditor__content">
                <section class="title-input">
                    <mat-form-field [style.fontSize]="13" appearance="fill"  class="textarea">
                          <textarea matInput
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="5"
                                    [(ngModel)]="title"
                                    id="title"
                                    placeholder="Título del evento..."
                                    (change)="changeTitle($event)"
                                    readonly
                                   ></textarea>
                    </mat-form-field>
                    <div class="form-row separador-form">
                        <div class="form-group">
                            <label>Servicio</label>
                            <input type="checkbox" class="check" [checked]="service" (change)="changeChkService()">
                            <label class="ml-10">Tarea</label>
                            <input type="checkbox" class="check" [checked]="task" (change)="changeChkTask()">
                        </div>
                    </div>
                    <div *ngIf="error && error.type=='title'" class="mb-10 flex-row pdl-10">
                         <div class="error-icon">
                            <i style="background: url('assets/img/icon-warning-red-border.svg') no-repeat; width:18px; height:18px; display:flex">
                            </i>
                         </div>
                        
                        <div class="message">
                            {{error.message}}
                        </div>
                    </div>
                </section>
                <section *ngIf="!task" class="customer-info" style="display:flex; flex-direction: row">
                    <div class="form-row separador-form mb-10">
                        <div class="form-group col-md-4 col-md-4-sm">
                            <label for="inputname "  >Teléfono</label>
                            <span class="require"> (*) </span>
                            <div class="flex-column">
                                <div #phoneInput></div>
                                <input id="phoneInput" type="tel"  class="form-control form-control-sm" *ngIf ="customerSel"  [(ngModel)]="customerSel.customer.TELEFONO"  (change)="getCustomerByPhone($event)" name="phoneInput" placeholder=""  />
                                <input id="phoneInput" type="tel"  class="form-control form-control-sm" *ngIf ="!customerSel"  [(ngModel)]="customerSel.customer.TELEFONO"  (change)="getCustomerByPhone($event)" name="phoneInput" placeholder="" />
                                <div class="info flex-row">
                                    <div class="info-logo">
                                        <i style="background: url('assets/img/icon-warning-blue-border.svg') no-repeat" class="info"></i>
                                    </div>
                                    <div class="info-search">Buscar por teléfono</div>
                               </div>
                            </div>
                        </div>
                     
                       <div class="form-group col-md-1 col-md-1-sm">
                            <label for="inputmat" >Matrícula/Vehículo</label>
                            <div class="flex-row">
                            
                                    <input *ngIf="!customerSel.customer.REGISTRATIONS || customerSel.customer.REGISTRATIONS.length==0 " type="text" class="form-control form-control-sm" name="inputpres"  [(ngModel)]="customerSel.customer.REGISTRATION">
                                
                                    <select *ngIf="customerSel.customer.REGISTRATIONS && customerSel.customer.REGISTRATIONS.length>0" class="form-control form-control-sm" [(ngModel)]="registration" (change)="changeRegistration()" >
                                        <option *ngFor="let item of customerSel.customer.REGISTRATIONS; let index= index">
                                            {{item}}
                                        </option>
                                    </select>
                            
                            </div>
                        </div>  
                       <div  class="form-group col-md-5 col-md-4-sm">
                            <label for="inputtlf" >Cliente</label>
                            <span class="require"> (*) </span>
                            <div class="flex-row">
                                <input type="text" class="form-control form-control-sm" name="inputname" *ngIf ="customerSel" [(ngModel)]="customerSel.customer.NOMCLTE"  (change)="changeName()">
                                <input type="text" class="form-control form-control-sm" name="inputname" *ngIf ="!customerSel" [(ngModel)]="nameCustomer"> 
                                <button   *ngIf ="customerSel" class="search" (click)="searchCustomer('name',customerSel.customer.NOMCLTE)">
                                    <i  class="icon-search" style="background:url('assets/custom/{{imagepath}}/icono/icon-search-black.svg') no-repeat; ">
                                   </i>
                               </button>
                               <button   *ngIf ="!customerSel" class="search" (click)="searchCustomer('name',nameCustomer)">
                                <i  class="icon-search" style="background:url('assets/custom/{{imagepath}}/icono/icon-search-black.svg') no-repeat; ">
                               </i>
                           </button>
                            </div>
                        </div>
                       
                        <div class="form-group col-md-4 col-md-4-sm">
                            <label for="inputmail" >Email</label>
                            <input type="text" style="width:-webkit-fill-available" class="form-control form-control-sm" name="inputemail" *ngIf ="customerSel" [(ngModel)]="customerSel.customer.EMAIL" >
                            <input type="text" style="width:-webkit-fill-available" class="form-control form-control-sm" name="inputemail" *ngIf ="!customerSel" >
                        </div>
                        
                        <div  class="form-group col-md-1 col-md-1-sm">
                            <label for="inputpres" >Presupuesto</label>
                            <!--<input *ngIf=" idDetail" type="text" class="form-control form-control-sm" name="inputpres"  [(ngModel)]="customerSel.customer.budget">-->
                            <input *ngIf="!customerSel.customer.budgets" type="text" class="form-control form-control-sm" name="inputpres"  [(ngModel)]="customerSel.customer.budget" (change)="changeBudget()">
                            <!--<select *ngIf="!idDetail" class="form-control form-control-sm" [(ngModel)]="budget" (change)="changeBudget()" >-->
                            <select *ngIf="customerSel.customer.budgets" class="form-control form-control-sm" [(ngModel)]="budget" (change)="changeBudget()" >
                                <option *ngFor="let item of customerSel.customer.budgets; let index= index">
                                    {{item}}
                                </option>
                            </select>
                        </div>  
                        <div  class="form-group col-md-4 col-md-4-sm">
                            <label for="inputpres" >O.R Fleet</label>
                            <input *ngIf="!customerSel.customer.orFleets" type="text" class="form-control form-control-sm" name="inputpres" (change)="changeORFleet()" [(ngModel)]="customerSel.customer.orFleet">
                        </div>  
                    </div> 
   
                </section>
                <section class="customer-info">
                    <div class="mb-15 flex-row pdl-10" *ngIf="error && (error.type=='phone' || error.type=='customer' || error.type=='registration')" >
                        <div class="error-icon">
                           <i style="background: url('assets/img/icon-warning-red-border.svg') no-repeat; width:18px; height:18px; display:flex">
                           </i>
                        </div>
                       
                       <div class="message">
                           {{error.message}}
                       </div>
                   </div>

                </section>

                <section class="date-from-content">
                    <div class="form-row separador-form mb-10" style="height:auto" id="visual-input-resources">
                        <div  *ngIf="service" class="form-group">
                            <label>Servicios</label>
                            <span class="require"> (*) </span>
                            <div  class="content-select" *ngIf="servicesNamesArr && servicesNamesSelectedArr.length==0">
                                <app-multi-select-options  [arrOptions]="servicesNamesArr"  [sel]="{id:0,value:''}"  [group]="{group:'service'}" [arrNewSelects]="newSelectServicesArr" (arrNewSelectsOut)="getValueServiceSel($event)"></app-multi-select-options>
                            </div>
                 
                            <div  class="content-select" *ngIf="servicesNamesSelectedArr.length>0">
                                <div *ngFor="let control of servicesNamesSelectedArr" style="display: flex; flex-direction:row">
                                    <label class="label-calendar mr-5 flex-row mb-5">{{control.name}}
                                        <a (click)="deleteServicesOptions(control)" class="calendar-delete">X</a>
                                    </label>
                                </div>

                               <button *ngIf="!setOpenService" (click)="addServicesOptions()" class="button plus-button mb-5 desktop">
                                    <span class="button-event">añadir</span>
                               </button>
                               
                               <button *ngIf="!setOpenService" (click)="addServicesOptions()" class="button plus-button mb-5 mobile">
                                    <span class="button-event">+</span>
                                </button>
                              
                            </div>
                          
                            <div *ngIf="setOpenService" class="group-selects">
                                <div *ngFor="let sel of newSelectServicesArr" class="mr-5">
                                    <app-multi-select-options  [arrOptions]="servicesNamesArr" [sel]="sel" [group]="{group:'service'}" [arrNewSelects]="newSelectServicesArr" (arrNewSelectsOut)="getValueServiceSel($event)"></app-multi-select-options>
                                </div>
                            </div>
                        </div>
                        <div  *ngIf="task" class="form-group col-md-4 col-md-4-sm">
                            <label>Tareas</label>
                            <span class="require"> (*) </span>
                            <div  class="content-select" *ngIf="arrExtrasServices">
                                <select class="form-control form-control-sm" [(ngModel)]="taskSelected.name" (change)="getTaskId()" >
                                    <option *ngFor="let item of arrExtrasServices">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="error && (error.type=='services' || error.type=='task')" class="mb-10 flex-row pdl-10">
                        <div class="error-icon">
                           <i style="background: url('assets/img/icon-warning-red-border.svg') no-repeat; width:18px; height:18px; display:flex">
                           </i>
                        </div>
                       
                       <div class="message">
                           {{error.message}}
                       </div>
                   </div>
                </section>

                <section class="date-from-content">
                    <div class="form-row separador-form  mb-10">
                       <div class="form-group col-md-4">
                            <label>Desde</label>
                            <input id="select-dateFrom" (click)="setActive(true,false)"  class="form-control form-control-sm" placeholder= {{this.dateFrom}} tabindex="2">
                            <div *ngIf="isActiveFrom" class="active" id="dateFrom-picker" style="display:none" >
                                    <app-date-picker (dateSelected)="clickDateSelected($event)"></app-date-picker>
                            </div>
                        </div>
                         
                        <div *ngIf="!allDay"  class="form-group col-md-4 date-sm" style="height:auto">
                                <label></label>
                                <select *ngIf="!allDay"  class="form-control form-control-sm"   [(ngModel)]='timeFrom' (change)="setTime(true, false)">
                                        <option [value]="item" *ngFor="let item of arrHoursFrom">{{item}}</option>
                                </select>
                        </div>

                        <div class="form-group col-md-4 ">
                            <label>Hasta</label>
                            <input [disabled]="repeat" id="select-dateTo"  (click)="setActive(false, true)" class="form-control form-control-sm" placeholder= {{this.dateTo}} tabindex="4">
                            <div *ngIf="isActiveTo" class="active" id="dateTo-picker" style="display:none">
                                <app-date-picker (dateSelected)="clickDateSelected($event)"></app-date-picker>
                            </div>
                        </div>
                        
                        <div *ngIf="!allDay"  class="form-group col-md-4 date-sm" style="height:auto">
                                <label></label>
                                <select *ngIf="!allDay" class="form-control form-control-sm" [(ngModel)]='timeTo' (change)="setTime(false, true)">
                                    <option [value]="item" *ngFor="let item of arrHoursTo">{{item}}</option>
                                </select>
                        </div>        
                    </div>
                   
                    <div class="options-recurrence">
                        <div class="form__checkbox mb-10 pdl-5">
                            <div class="all-day-label">
                                <div class="input all-day">
                                    <input type="checkbox" class="check"  id="all_day" name="all_day" value="0" [checked]="allDay" (change)="setAllDay($event, allDay=!allDay)">
                                    <label for="all_day" tabindex="6">Todo el día</label>
                                </div>
                            </div>
                            <div class="visual-input recurrence">
                                <div>
                                    <div class="form__checkbox recurrence-label">
                                        <div class="input all-day">
                                            <input type="checkbox" class="check" id="recurs" name="recurs" value="0" [checked]="repeat" (change)="changeRepeatCheck()">
                                            <label for="recurs"  tabindex="7">Se repite</label>
                                            <div *ngIf="objParams.repeat && repeat" id='params' class="params">
                                                <a class="soft-button" (click)="goToRecurrence()">Mostrar</a>
                                            </div>
                                        </div>
                                    </div>
                                   <!---- <span>&nbsp;</span>-->
                                </div>
                            </div>
                        </div>
                       
                        <div *ngIf="objParams.repeat? show: repeat" class="repeat-section">
                            <mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event)" animationDuration="0ms">  
                                <mat-tab label="Diariamente">
                                    <span> 
                                       <app-recurrence-events [type]="type" [objParamsVar]="objParams" (objParams)="getParams($event)"></app-recurrence-events>
                                    </span>
                                </mat-tab>  
                                <mat-tab label="Semanalmente">
                                    <span>
                                        <app-recurrence-events [type]="type" [objParamsVar]="objParams"  (objParams)="getParams($event)"></app-recurrence-events>
                                    </span>
                                  </mat-tab> 
                                  <mat-tab label="Mensualmente" >
                                    <span>
                                        <app-recurrence-events [type]="type" [objParamsVar]="objParams" (objParams)="getParams($event)"></app-recurrence-events>
                                    </span>
                                  </mat-tab>
                                  <mat-tab label="Anualmente" >
                                    <span>
                                        <app-recurrence-events [type]="type" [objParamsVar]="objParams" (objParams)="getParams($event)"></app-recurrence-events>
                                    </span>
                                  </mat-tab>  
                              </mat-tab-group>
                              <br>
                        </div>
                         
                    </div>

                </section>
                <section class="date-from-content">
                    <div class="form-row separador-form mb-10" style="height:auto" id="visual-input-resources">
                        <div class="form-group">
                           <!---- <i style="background:url('assets/img/format-list-bulleted.svg')" class="icon-resource visual-input-icon"></i>-->
                            <label>Calendario</label>
                            <span class="require"> (*) </span>
                            <div  class="content-select" *ngIf="calendarsNamesArr && calendarsNamesSelectedArr.length==0">
                                <app-multi-select-options [arrOptions]="calendarsNamesArr"  [sel]="{id:0,value:''}" [group]="{group:'none'}" [arrNewSelects]="newSelectArr" (arrNewSelectsOut)="getValueSel($event)"></app-multi-select-options>
                            </div>

                            <div  class="content-select" *ngIf="calendarsNamesSelectedArr.length>0">

                                <div *ngFor="let control of calendarsNamesSelectedArr" style="display: flex; flex-direction:row">
                                    <label class="label-calendar mr-5 flex-row mb-5">{{control.name}}
                                        <a (click)="deleteCalendarsOptions(control)" class="calendar-delete">X</a>
                                    </label>
                                </div>

                           
                               <button *ngIf="!setOpen" (click)="addCalendarsOptions()" class="button plus-button mb-5 desktop">
                                    <span class="button-event">añadir</span>
                               </button>
                               <button *ngIf="!setOpen" (click)="addCalendarsOptions()" class="button plus-button mb-5 mobile">
                                <span class="button-event">+</span>
                           </button>
                              
                            </div>

                            <div *ngIf="setOpen" class="group-selects">
                                <div *ngFor="let sel of newSelectArr" class="mr-5">
                                    <app-multi-select-options   [arrOptions]="calendarsNamesArr" [sel]="sel" [group]="{group:'none'}" [arrNewSelects]="newSelectArr" (arrNewSelectsOut)="getValueSel($event)"></app-multi-select-options>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="error && (error.type=='calendars')" class="mb-10 flex-row pdl-10">
                        <div class="error-icon">
                           <i style="background: url('assets/img/icon-warning-red-border.svg') no-repeat; width:18px; height:18px; display:flex">
                           </i>
                        </div>
                       
                       <div class="message">
                           {{error.message}}
                       </div>
                   </div>
                </section>
            </section>
               
            <section class="eventeditor__rest">
                <div  class="form-row separador-desc mb-10">
                    <div class=" form-group col-md-6" style="padding-left:10px" >
                       <!---- <i style="background:url('assets/img/page-next.svg')"  class="icon-description  visual-input-icon"></i>-->
                        <label>Descripción</label>
                        <input type="text" id= 'input3' name=" description"  [(ngModel)]="description"  class="event-description form-control form-control-sm" tabindex="24" rows="1" maxlength="255"  placeholder="Descripción del evento..."/>   
                    </div>
                </div>
                    
            </section>
            <section class="eventeditor__info" *ngIf="extendedProps">
                  <div  class="wysiwyg info">
                        <div *ngIf="extendedProps.idUserCreate" class="info-datetime">Evento creado por  
                            <span><strong>{{extendedProps.idUserCreate.firstName}}, </strong> el {{createdDate}} a las {{createdTime}} hrs.  </span>
                            <span *ngIf="extendedProps.idUserLastUpdate">Última actualización realizada por {{extendedProps.idUserLastUpdate.firstName}}, el {{updatedDate}} a las {{updatedTime}} hrs</span> 
                        </div> 
                  </div>
            </section>

            <div class="menu-buttons">
                <div>
                    <button *ngIf="this.title" (click)="deleteEvent()"  type="submit" class="button delete-button button-gral" >
                        <i class="sprite-event-check-white" style="background:url('assets/img/trash-can-outline.svg')">
                        </i>
                       <span class="button-event">Eliminar</span>
                    </button>
                </div>
                <div class="mr-5 ml-5">
                    <button (click)="saveEvent()" type="submit" class="button save-button button-gral">
                        <i class="sprite-event-check-white" style="background:url('assets/img/check.svg')">
                        </i>
                        <span class="button-event">Guardar</span>
                   </button>
                </div>
                <div  *ngIf="this.title && updatedDate && !this.task && customerSel.customer.TELEFONO">
                    <button  type="submit" class="button delete-button button-gral" (click)="sendWhatsapp()">
                        <i class="sprite-event-check-white" style="background:url('assets/img/icon-whatsapp24px.svg') no-repeat">
                        </i>
                        <span class="button-event">Whatsapp</span>
                    </button>
                   
                </div>

              <!--<div id="share-mobile" *ngIf="this.title && updatedDate" class="title" style="margin-right:55px">
                    <button  type="submit" class="button delete-button mobile" (click)="sendMessage()">
                        <i class="sprite-event-check-white" style="background:url('assets/img/icon-compartir.svg')">
                        </i>
                        <span class="button-event"></span>
                    </button>
                        <ul id="share-1-mobile" style="display:none" class="menu-share">
                                    <li  class="share-list-header">Con otras personas 
                                     
                                    </li>
                                    <li class="share-list" >
                                        <i class="sprite-event-check-white-small" style="background:url('assets/img/icon-whatsapp.svg')">
                                        </i>
                                      
                                        <a (click)="sendWhatsapp()">Whatsapp</a>
                                    </li> 
                        </ul>
                </div>-->
            </div>
        </div>
        <div #messages></div>
        <div #seriesevents></div>
        <div #share></div>
        <div #customer></div>
        
    </div>
</div>
