<div class="user__page">
  <section class="desktop calendar-header">
    <div class="logo common-margins-logo"> 
      <div>
        <img class="logo-image" src="assets/custom/{{imagepath}}/icono/logo.svg"/>
      </div>
      <div class="common-margins-title" >
        <span class="title-logo">1Cal</span>
      </div>
    </div>
    <div class="header-rest common-margins">
      <h1 class="title">
        Dashboard
      </h1>
    </div>
    <div class="search common-margins">
     <div style="width:100%">
       <div class="container-1">
        <span  *ngIf="filterValue.length==0" class="image-search">
           <i style="background: url('assets/custom/{{imagepath}}/icono/icon-search.svg') no-repeat; width:38px">
          </i></span>
           <input id="Buscador" type="text" name="filterValue" placeholder="Buscar" [(ngModel)]="filterValue"  autocomplete="off"> 
        </div>
      </div>
    </div>
    <div class="user-avatar">
      <div class="sprite-calendar-img-avatar-header">
         <img src="assets/custom/{{imagepath}}/icono/logout.svg" (click)="clickExit($event)">
      </div>
    </div>
  </section>
  <section class="mobile calendar-header">
    <div class="calendar-header-sm-m">
       <div class="logo"> 
         <div class="logo-image-content">
           <img class="logo-image" src="assets/custom/{{imagepath}}/icono/logo.svg"/>
         </div>
       </div>
       <div class="header-rest">
         <h1 class="title">
           Dashboard
         </h1>
       </div>
       <div class="search common-margins mobile-medium">
         <div >
          <div class="container-1">
              <span *ngIf="filterValue.length==0" class="image-search">
                <i style="background: url('assets/custom/{{imagepath}}/icono/icon-search.svg') no-repeat; width:38px">
                </i>
              </span>
              <input id="Buscador" type="text" name="filterValue" placeholder="Buscar" [(ngModel)]="filterValue" autocomplete="off">
           </div>
         </div>
      </div>
      <div class="user-avatar">
        <div class="sprite-calendar-img-avatar-header">
           <img src="assets/custom/{{imagepath}}/icono/logout.svg"  (click)="clickExit($event)">
        </div>
      </div>
    </div>
    <div class="search common-margins mobile-small">
     <div>
        <div class="container-1">
          <span *ngIf="!filterValue" class="image-search">
            <i style="background: url('assets/custom/{{imagepath}}/icono/icon-search.svg') no-repeat; width:38px">
            </i>
          </span>
           <input id="Buscador" type="text"  name="filterValue" placeholder="Buscar" [(ngModel)]="filterValue" autocomplete="off">
        </div>
     </div>
    </div>
  </section>

  <section class="user__page-wrapper">
    <section class="user__page-content">
      <div class="user__page-dashboard" id="user__page-dashboard">
        <div class="calendar-box-wrapper">
          <div class="calendar-box-section">
            <a *ngFor="let workplace of workplaces | filter: filterValue" class="calendar-box-link">
              <div (click)="clickWorkPlace(workplace.idWorkPlace, workplace.name, workplace.address, workplace.inhouseWorkPlace, workplace.phone)" class="calendar-box-content">
                <div>
                  <mat-icon class="icon-menu" svgIcon="workshop"></mat-icon>
                </div>
                <div class="calendar-name">
                  <h4>{{workplace.name}}</h4>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  </section>
  <router-outlet></router-outlet>
</div>
<router-outlet></router-outlet>