<div class="modal-associate" id="modal" >
    <div class="modal__window-associate eventeditor-associate" cdkDrag >
        <a (click)='closeEvent($event)' class="button ghost close-icon desktop">
            <i class="sprite-event-close" style="background:url('assets/img/close-black.svg')"></i>
        </a>
        <div class="content-height-associate" id="content-height">
            <section cdkDragHandle  class="eventeditor__header-associate">                
               <div class="menu">
                <div>
                    <button *ngIf="this.title" (click)="deleteEvent()"  type="submit" class="button delete-button mobile" >
                        <i class="sprite-event-check-white" style="background:url('assets/img/trash-can-outline.svg')">
                        </i>
                       <span class="button-event x-small">Eliminar</span>
                    </button>
                </div>
                <div style="margin-right:55px">
                    <button (click)="saveEvent()" type="submit" class="button save-button mobile">
                        <i class="sprite-event-check-white" style="background:url('assets/img/check.svg')">
                        </i>
                        <span class="button-event x-small">Guardar</span>
                   </button>
                </div>

               <!---- <div id="share-mobile" *ngIf="this.title && updatedDate" class="title" style="margin-right:55px">
                    <button  type="submit" class="button delete-button mobile" (click)="sendMessage()">
                        <i class="sprite-event-check-white" style="background:url('assets/img/icon-compartir.svg')">
                        </i>
                        <span class="button-event"></span>
                    </button>
                        <ul id="share-1-mobile" style="display:none" class="menu-share">
                                    <li  class="share-list-header">Con otras personas 
                                     
                                    </li>
                                    <li class="share-list" >
                                        <i class="sprite-event-check-white-small" style="background:url('assets/img/icon-whatsapp.svg')">
                                        </i>
                                      
                                        <a (click)="sendWhatsapp()">Whatsapp</a>
                                    </li> 
                        </ul>
                </div>-->


                <div>
                    <a (click)='closeEvent($event)' class="button ghost close-icon mobile">
                        <i class="sprite-event-close" style="background:url('assets/img/close-black.svg')"></i>
                    </a>
                </div>
                    <ng-content select="[a]"></ng-content>
                    &nbsp;
                </div>

            </section>
            <section class="eventeditor__content">
                <section class="title-input-associate">
                
                              <mat-form-field [style.fontSize]="13" appearance="fill"  class="textarea">
                                    <textarea matInput
                                              cdkTextareaAutosize
                                              #autosize="cdkTextareaAutosize"
                                              cdkAutosizeMinRows="1"
                                              cdkAutosizeMaxRows="5"
                                              [(ngModel)]="title"
                                              id="title"
                                              placeholder="Introduce el título del evento..."
                                             ></textarea>
                                  </mat-form-field>
                           
  
                </section>

                  <div class="visual-input-associate visual-input__left">
                    <div class="flex_content">
                        <div class="dates">
                            <div  class="datesfrom">
                                <div class="flex_content">
                                    <i class="sprite-event-clock" style="background:url('assets/img/clock-time-nine3.svg')"></i>
                                    <label class="date-label labelfrom from">Desde</label>
                                </div>
                                
                                <div  class="value date-from-value">
                                    <div class="datepicker">
                                        <input name="dateFrom" tabindex="2" type="hidden" class="flatpickr-input">
                                        <input id="select-dateFrom" (click)="setActive(true,false)" class="input flatpickr-input" placeholder= {{this.dateFrom}} tabindex="2" type="text">
                                        <div *ngIf="isActiveFrom" class="active" id="dateFrom-picker" style="display:none" >
                                            <app-date-picker (dateSelected)="clickDateSelected($event)"></app-date-picker>
                                        </div>
                                    </div>
                                
                                        <div *ngIf="!allDay" class="select-widget timepicker from" >
                                            <select class="select-widget-input" [(ngModel)]='timeFrom' (change)="setTime(true, false)">
                                                <option [value]="item" *ngFor="let item of arrHoursFrom" class="select-option">{{item}}</option>
                                            </select>
                                        </div>

                                </div>
                            </div>
                            
                    
                            <div class="flex_content">
                                <div class="flex_content">
                                    <label class="date-label to">Hasta</label>
                                </div>
                                <div class="value date-to-value">
                                        <div class="datepicker">
                                            <input  name="dateTo" tabindex="4" type="hidden" class="flatpickr-input" >
                                            <input [disabled]="repeat" id="select-dateTo"  (click)="setActive(false, true)" class="input flatpickr-input" placeholder= {{this.dateTo}} tabindex="4" type="text">
                                            <div *ngIf="isActiveTo" class="active" id="dateTo-picker" style="display:none">
                                                <app-date-picker (dateSelected)="clickDateSelected($event)"></app-date-picker>
                                            </div>
                                        </div>
                                
                                        <div *ngIf="!allDay" class="select-widget timepicker to " >
                                            <select class="select-widget-input" [(ngModel)]='timeTo' (change)="setTime(false, true)">
                                                <option [value]="item" *ngFor="let item of arrHoursTo" class="select-option">{{item}}</option>
                                            </select>
                                        </div>
                                </div>
                            </div>    
                        </div>
                        <section class="eventeditor__sidebar">
                            <button (click)="saveEvent()" type="submit" class="button save-button">
                               <i class="sprite-event-check-white" style="background:url('assets/img/check.svg')">
                                </i>
                                 <span class="button-event">Guardar</span>
                            </button>
                             <!-----Prueba api whatsapp>-->
                        <!----   <div id="share" *ngIf="this.title && updatedDate" class="title">
                                <button  type="submit" class="button delete-button" (click)="sendMessage()">
                                    <i class="sprite-event-check-white" style="background:url('assets/img/icon-compartir.svg')">
                                    </i>
                                    <span class="button-event">Compartir</span>
                                </button>
                                    <ul id="share-1" style="display:none" class="menu-share">
                                                <li  class="share-list-header">Con otras personas 
                                               
                                                </li>
                                                <li class="share-list" >
                                                    <i class="sprite-event-check-white-small" style="background:url('assets/img/icon-whatsapp.svg')">
                                                    </i>
                                             
                                                    <a (click)="sendWhatsapp()">Whatsapp</a>
                                                </li> 
                                    </ul>
                            </div>-->
                            <div *ngIf="this.title" class="title">
                                <button (click)="deleteEvent()"  type="submit" class="button delete-button" >
                                    <i class="sprite-event-check-white" style="background:url('assets/img/trash-can-outline.svg')">
                                    </i>
                                    <span class="button-event">Eliminar</span>
                                </button>
                           </div>
                           <!--<div class="title" *ngIf="confirmWhatsapp"><span class="title whatsapp">*Whatsapp enviado*</span></div>-->
                       </section>
                    </div>
                    <div class="options-recurrence">
                            <div class="form__checkbox-associate all-day-label">
                                <div class="input all-day">
                                    <input type="checkbox" class="check"  id="all_day" name="all_day" value="0" [checked]="allDay" (change)="setAllDay($event, allDay=!allDay)">
                                    <label for="all_day" tabindex="6">Todo el día</label>
                                </div>
                            </div>
                            <div class="visual-input-associate recurrence">
                                <div>
                                    <div class="form__checkbox-associate recurrence-label">
                                        <div class="input all-day">
                                            <input type="checkbox" class="check"  id="recurs" name="recurs" value="0" [checked]="repeat" (change)="changeRepeatCheck()">
                                            <label for="recurs"  tabindex="7">Se repite</label>
                                            <div *ngIf="objParams.repeat && repeat" id='params' class="params">
                                                <a class="soft-button" (click)="goToRecurrence()">Mostrar</a>
                                            </div>
                                        </div>
                                    </div>
                                    <span>&nbsp;</span>
                                </div>
                            </div>
                            <div *ngIf="objParams.repeat? show: repeat" class="repeat-section">
                                <mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event)" animationDuration="0ms">  
                                    <mat-tab label="Diariamente">
                                        <span> 
                                           <app-recurrence-events [type]="type" [objParamsVar]="objParams" (objParams)="getParams($event)"></app-recurrence-events>
                                        </span>
                                    </mat-tab>  
                                    <mat-tab label="Semanalmente">
                                        <span>
                                            <app-recurrence-events [type]="type" [objParamsVar]="objParams"  (objParams)="getParams($event)"></app-recurrence-events>
                                        </span>
                                      </mat-tab> 
                                      <mat-tab label="Mensualmente" >
                                        <span>
                                            <app-recurrence-events [type]="type" [objParamsVar]="objParams" (objParams)="getParams($event)"></app-recurrence-events>
                                        </span>
                                      </mat-tab>
                                      <mat-tab label="Anualmente" >
                                        <span>
                                            <app-recurrence-events [type]="type" [objParamsVar]="objParams" (objParams)="getParams($event)"></app-recurrence-events>
                                        </span>
                                      </mat-tab>  
                                  </mat-tab-group>
                            </div>
                                <br>
                        </div>
                    </div>

                    <div class="visual-input-associate resources" style="height:auto" id="visual-input-resources">
                            <i style="background:url('assets/img/format-list-bulleted.svg')" class="icon-resource visual-input-icon"></i>
                            <label class="resources-label">Calendario</label>
                           <div id="calendars-content" class="calendarpicker" style="height: auto">
                                <div id="calendars-names"  (click)="setOpenSelect(true)" class="select-control">
                                    <div *ngIf="calendarsNamesSelectedArr.length">
                                        <div id="list-names-selected" *ngFor="let nameselected of calendarsNamesSelectedArr" class="select-multi-value-wrapper">
                                            <div  id="select-value" class="select-value" >
                                                 <span  (click)="clickDeleteName(nameselected)" class="select-value-icon">×</span>
                                                <input id="select-input" type="text"  class="select-value-label"> {{nameselected.name}}
                                            </div>
                                        </div>
                                        <!---<div id="selected-name" style="display:none">
                                            <select class="select-dropdown-names" *ngIf="openSelect" [(ngModel)]="names" (change)="setNames(names.name, names.idCalendar)">
                                                <option [ngValue]="name" *ngFor="let name of calendarsNamesArr">{{name.name}}</option>
                                            </select>
                                        </div>--->
                                        <div id="selected-name" style="display:none" >
                                            <div *ngIf="openSelect" class="select-dropdown-names" >
                                                <div  class="names-option" *ngFor="let name of calendarsNamesArr">
                                                    <div *ngIf="openSelect" class="select-option" (click)="setNames(name.name, name.idCalendar)">{{name.name}}</div>
                                                </div>
                                            </div> 
                                       </div>
                                 </div>
                                 <div *ngIf="calendarsNamesSelectedArr.length==0">
                                        <div  id="select-value" class="select-value list" >
                                             <span  (click)="clickDeleteName(nameselected)" class="select-value-icon"></span>
                                            <input id="select-input" type="text"  class="select-value-label"> {{calendarsNamesSelectedArr}}
                                        </div>
                                    
                                   <!----<div id="selected-name" style="display:none" >
                                        <select class="select-dropdown-names " *ngIf="openSelect" [(ngModel)]="names" (change)="setNames(names.name, names.idCalendar)">
                                            <option  [ngValue]="name" *ngFor="let name of calendarsNamesArr">{{name.name}}</option>
                                        </select>
                                    </div>-->
                                        <div id="selected-name" style="display:none" >
                                            <div *ngIf="openSelect" class="select-dropdown-names" >
                                                <div  class="names-option" *ngFor="let name of calendarsNamesArr">
                                                    <div *ngIf="openSelect" class="select-option" (click)="setNames(name.name, name.idCalendar)">{{name.name}}</div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                               </div> 
                            </div>
                     </div>
                </section>
               
                <section class="eventeditor__rest">
                    <div  class="wysiwyg wycontent visual-input-associate ">
                        <i style="background:url('assets/img/page-next.svg')"  class="icon-description  visual-input-icon"></i>
                        <label class="label-description">Descripción</label>
                        <div>
                            <div>
                                <div class=" wysiwyg wytext">
                                    <input type="text" id= 'input3' name=" description"  [(ngModel)]="description"  class="event-description" tabindex="24" rows="1" maxlength="255"  placeholder="Descripción del evento..."/>
                              </div>
                           <input type="hidden" name="notes" value="">
                          </div>
                       </div>
                  </div>
       
                   <div class="content-height-associate">
                        <div class="message">
                        {{message}} 
                        </div>
                   </div>
                </section>
                <section class="eventeditor__info" *ngIf="extendedProps">
                    <div  class="wysiwyg info">
                        <div *ngIf="extendedProps.idUserCreate" class="info-datetime">Evento creado por  
                            <span><strong>{{extendedProps.idUserCreate.firstName}}, </strong> el {{createdDate}} a las {{createdTime}} hrs.  </span>
                            <span *ngIf="extendedProps.idUserLastUpdate">Última actualización realizada por {{extendedProps.idUserLastUpdate.firstName}}, el {{updatedDate}} a las {{updatedTime}} hrs</span> 
                        </div> 
                    </div>
                </section>
        </div>
        <div #messages></div>
        <div #seriesevents></div>
        <div #share></div>
    </div>
</div>
