import { Component, Input, Output, OnInit, EventEmitter, ViewChild, ViewContainerRef ,ElementRef, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { GlobalVariables } from 'src/app/services/local/globalVariables.service';
import { addHourToStr, calculateTimeofRangeHours, validateDatesEN, parseDate, parseDateToTime,parseDateTime,parseDateTimeZ,transfromDateTimeENT, recalculateArrHours, transfromDateEN, transfromStringtoDate, getTimeNumber  } from '../../shared/utilities/utilities'
import { DatePipe } from '@angular/common';
import { UtilsService } from 'src/app/services/utils.service';
import { ShowMessageComponent } from 'src/app/shared/modal/show_message/show-message.component';
import { Recurrence } from 'src/app/models/recurrence';
import { SeriesEventsComponent } from 'src/app/shared/modal/series-events/series-events.component';
import { HttpClient } from '@angular/common/http';
import { Subscriber } from 'src/app/shared/helpers/subscriber';
import { ShareEventsComponent } from 'src/app/shared/modal/share-events/share-events.component';






@Component({
  selector: 'app-add-new-events-associate',
  templateUrl: './add-new-events-associate.component.html',
  styleUrls: ['./add-new-events-associate.component.css'],
 encapsulation: ViewEncapsulation.None
})
export class AddNewEventsAssociateComponent extends Subscriber implements OnInit {
  @ViewChild ('names') name: ElementRef;
  @ViewChild('messages', { read: ViewContainerRef }) showContainerRef: ViewContainerRef;
  @ViewChild('seriesevents', {read: ViewContainerRef}) seriesContainerRef: ViewContainerRef;
  @ViewChild('share', {read: ViewContainerRef}) shareContainerRef: ViewContainerRef;


  
  @Input() workPlaceId: any;
  @Input() workPlaceName: any;
  @Input() calendar: any;
  @Input() idDetail: any;
  @Input() description: any;
  @Input() allDay:any;
  @Input() title:string;
  @Input() dateDefault: any;
  @Input() endDate: any;
  @Input() resourceId: any;  //ver si quitar este
  @Input() nameresourceId:any; // ver si quitar este
  @Input() calendarsNamesArr: Array<any>=[];
  @Input() resourceIds: Array<any>;
 // @Input() objParams= new Recurrence();
  @Input() repeatParams: any;
  @Input() startTimeWorkplace: any;
  @Input() currentDateRepeat: any;
  @Input() extendedProps: any

  @Output() selectedIdCalendars =new EventEmitter<any>();
  @Output() description_event= new EventEmitter<any>();
  @Output() closeMeEvent = new EventEmitter();
  @Output() submitModal = new EventEmitter();
  @Output() deleteMeEvent = new EventEmitter();


  constructor(private api: ApiService, 
    private globalVariables: GlobalVariables, 
    private utilsService: UtilsService,
    private http: HttpClient,
   )  {
      super();
    }

  resource: any;
  data: any ={};
  isOpenFrom: any = false;
  isOpenTo: any=false;
  isActiveFrom: any =false;
  isActiveTo: any = false;
  pipe =new DatePipe('ES');
  calendarsNamesSelectedArr: Array<any>=[];
  clickDeleteButton: boolean=false;
  dateTo: any='';
  dateFrom: any='';
  timeFrom: any;
  timeTo: any;
  message: string='';
  newEvents: Array<any>=[];
  tabIndex = 0;
  startDay:string='08:00:00';
  endDay:string='20:00:00';
  type:string="daily";
  repeat: boolean;
  show:boolean;
  objParams= new Recurrence();
  typeDelete: string='';
  typeUpdate: string='';
  calendarsNamesArrClone: Array<any>=[];
  duration: any ={};
  openSelect: boolean;
  closeDropDown:boolean
  names: Array<any>=[]
  createdDate: any;
  createdTime: any;
  updatedDate: any;
  updatedTime: any;
  arrHours: Array<any>=[]
  arrHoursTo: Array<any>=[]
  arrHoursFrom: Array<any>=[]
  clickOpenOptions: Boolean=false
  confirmWhatsapp: boolean
  phone:any
  

 
  preventDrag() {
    return false;
  }


  ngOnInit(): void {
 
    this.names                = this.calendarsNamesArr[0]
    this.arrHours             = this.globalVariables.arrHours
    this.startTimeWorkplace   = (getTimeNumber(this.startTimeWorkplace) + ":" +"00").toString()
    this.arrHoursFrom         = recalculateArrHours(this.startTimeWorkplace, this.arrHours)

    if (this.extendedProps!='')
    {
      if (this.extendedProps.createdDate)
      {
        this.createdDate        = transfromStringtoDate(parseDate(this.extendedProps.createdDate)) 
        this.createdTime        = parseDateToTime(parseDateTimeZ(this.extendedProps.createdDate))
      }

      if (this.extendedProps.updatedDate)
      {
        this.updatedDate        = transfromStringtoDate(parseDate(this.extendedProps.updatedDate))
        this.updatedTime        = parseDateToTime(parseDateTimeZ(this.extendedProps.updatedDate))
      }
      
    }
     
 
    if(this.dateDefault && !this.allDay && !this.endDate)
    {
      this.dateFrom           = parseDate(this.dateDefault);
      this.dateFrom           = transfromStringtoDate(this.dateFrom)
      this.dateTo             = parseDate(this.dateDefault);
      this.dateTo             = transfromStringtoDate(this.dateTo)
      this.timeFrom           = parseDateToTime(this.dateDefault);
      this.timeTo             = parseDateToTime(this.dateDefault);
      this.timeTo             = addHourToStr(this.timeTo,':')
   
    }

    else {if(this.dateDefault && this.allDay) {   
   
      this.dateFrom           = this.dateDefault + ' ' + this.startDay
      this.dateFrom           = transfromStringtoDate(this.dateFrom)
      this.dateTo             = this.dateDefault + ' ' + this.endDay
      this.dateTo             = transfromStringtoDate(this.dateTo)
      this.timeFrom           = this.startDay;
      this.timeTo             = this.endDay;
   
      }
       else {if(this.dateDefault && this.endDate)
        { 
          this.dateFrom       = parseDate(this.dateDefault);
          this.dateFrom       = transfromStringtoDate(this.dateFrom)
          this.dateTo         = parseDate(this.dateDefault);
          this.dateTo         = transfromStringtoDate(this.dateTo)
          this.timeFrom       = parseDateToTime(this.dateDefault);
          this.timeTo         = parseDateToTime(this.endDate);
     
        }}

    }
   
    this.duration= calculateTimeofRangeHours(this.timeFrom,this.timeTo)
    this.globalVariables.setdateFromEvent(transfromDateEN(this.dateFrom))
    this.globalVariables.setdateToEvent(transfromDateEN(this.dateTo))
    this.globalVariables.sethourFromEvent(this.timeFrom)
    this.globalVariables.sethourToEvent(this.timeTo)

    
    this.arrHoursTo= recalculateArrHours(this.timeFrom, this.arrHours)
   
    if (this.repeatParams)
    {
      this.initializeObjParams()
      if(this.repeatParams.repeat){
        this.repeat=true
        this.show=false
        this.type=this.repeatParams.repeatType
      }
      
    }
    else this.repeat=false

    if(this.resourceIds) this.calendarsNamesSelectedArr=this.resourceIds
     else if(this.nameresourceId){
        this.calendarsNamesSelectedArr.push({'idCalendar':this.resourceId,'name':this.nameresourceId})
      }

    if (this.title)
    { const arrMatch: Array<any>= this.title.match('(?!9)[0-9]{9}')
      if (arrMatch){
        if(arrMatch[0].length<10)
          this.phone= arrMatch[0]
        else this.phone=''
      }
      else
        this.phone='' }
    else this.phone=''

   // console.log(this.title.match('(?!9)[0-9]{9}'), 'resultado del match de title')
   // console.log(this.phone,'this phone')
   
    this.selectedIdCalendars.emit(this.calendarsNamesSelectedArr)
    this.ngAfterViewInit();

  
  }

  ngAfterViewInit() {
    const title: HTMLElement = document.getElementById('title');
    title.focus();
  }


  onKeydown(event: KeyboardEvent): void {
    if ( [event.code, event.key].includes('Enter') ) {
      this.saveEvent();
    }
  }

  saveEvent(): void{
    if(!this.repeat)
    {this.clearObjParams()}
    if(this.idDetail) this.editEvent();
    else this.saveNewEvent();
  }

  setActive(value1?: boolean, value2?: boolean){  

    if(value1)
    { this.isActiveFrom=value1;
      this.isActiveTo=false
     }
  
    if(value2)
    { this.isActiveTo=value2;
      this.isActiveFrom=false
    
    }
    
    if (this.isActiveFrom){
      document.addEventListener('click', function(e) {
        if(document.getElementById('select-dateFrom') !=null)
        {
          if (document.getElementById('select-dateFrom').contains(e.target as Node)) {
            document.getElementById('dateFrom-picker').style.display="block"
           
          } 
          else {
            if(document.getElementById('next')!=null){
              if(document.getElementById('next').contains(e.target as Node)) {
                if(document.getElementById('dateFrom-picker') !=null){
                  document.getElementById('dateFrom-picker').style.display="block"
                }
               }
            }
           
            else if(document.getElementById('prev')){
              if(document.getElementById('prev').contains(e.target as Node)) {
                if(document.getElementById('dateFrom-picker') !=null){
                  document.getElementById('dateFrom-picker').style.display="block"
                }
              }
            }
            
              else 
                if (!document.getElementById('select-dateFrom').contains(e.target as Node)){
                  if( document.getElementById('dateFrom-picker')!=null)
                  document.getElementById('dateFrom-picker').style.display="none"
               }
          
          }
        }
      })
    }

  
   if (this.isActiveTo && !this.repeat){
      document.addEventListener('click', function(e) {
        if(document.getElementById('select-dateTo') !=null)
        {
          if (document.getElementById('select-dateTo').contains(e.target as Node)) {
            document.getElementById('dateTo-picker').style.display="block"
      
          } 
          else {
            if(document.getElementById('next')!=null){
              if(document.getElementById('next').contains(e.target as Node)) {
                if(document.getElementById('dateTo-picker') !=null){
                  document.getElementById('dateTo-picker').style.display="block"
                }
              }
            }
          
            else 
            if(document.getElementById('next')!=null){
              if(document.getElementById('prev').contains(e.target as Node)) {
                if(document.getElementById('dateTo-picker') !=null){
                  document.getElementById('dateTo-picker').style.display="block"
                }
              }
              }
        
            else 
                if  (!document.getElementById('select-dateTo').contains(e.target as Node)){
                  if( document.getElementById('dateTo-picker')!=null){
                      document.getElementById('dateTo-picker').style.display="none"
                    }
                      
                  }
          }    
        }
      })
    }  
  }




  initializeObjParams(){
    this.objParams.repeat                = this.repeatParams.repeat,
    this.objParams.type                  = this.repeatParams.repeatType,
    this.objParams.repeatAlways          = this.repeatParams.repeatAlways,
    this.objParams.repeatDuring          = this.repeatParams.repeatDuring,
    this.objParams.repeatEvery           = this.repeatParams.repeatEvery,
    this.objParams.repeatUntil           = this.repeatParams.repeatUntil,
    this.objParams.repeatWeekdayPosition = this.repeatParams.repeatWeekdayPosition,
    this.objParams.repeatWeeklyFriday    = this.repeatParams.repeatWeeklyFriday,
    this.objParams.repeatWeeklyMonday    = this.repeatParams.repeatWeeklyMonday,
    this.objParams.repeatWeeklySaturday  = this.repeatParams.repeatWeeklySaturday,
    this.objParams.repeatWeeklySunday    = this.repeatParams.repeatWeeklySunday,
    this.objParams.repeatWeeklyThursday  = this.repeatParams.repeatWeeklyThursday,
    this.objParams.repeatWeeklyTuesday   = this.repeatParams.repeatWeeklyTuesday,
    this.objParams.repeatWeeklyWednesday = this.repeatParams.repeatWeeklyWednesday
  }

  clearObjParams(){
    this.objParams.repeat                = false,
    this.objParams.type                  = '',
    this.objParams.repeatAlways          = false,
    this.objParams.repeatDuring          = 0,
    this.objParams.repeatEvery           = 0,
    this.objParams.repeatUntil           = undefined,
    this.objParams.repeatWeekdayPosition = 0,
    this.objParams.repeatWeeklyFriday    = false,
    this.objParams.repeatWeeklyMonday    = false,
    this.objParams.repeatWeeklySaturday  = false,
    this.objParams.repeatWeeklySunday    = false,
    this.objParams.repeatWeeklyThursday  = false,
    this.objParams.repeatWeeklyTuesday   = false,
    this.objParams.repeatWeeklyWednesday = false
  }




setOpenSelect(value)
{
  
  var content=document.getElementById('calendars-content')

  if (content.contains(document.getElementById('selected-name') as Node))
    this.openSelect=true
   
  var select=  document.getElementById('selected-name') 
  var visual= document.getElementById('visual-input-resources')

  var contentheight = document.getElementById('content-height')
 
  if(!this.openSelect){
    this.openSelect=value
    this.closeDropDown=false

  }
    

  if(this.openSelect){

    if(select==null) select= document.getElementById('selected-name') 

    if(content !=null && select!=null){
      visual.addEventListener('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        if((!content.contains(e.target as Node))){
          select.style.display="none"
         
        }
        else
        {
          select.style.display="flex"
        }
        
      },false)
 
    }
  }
}



setNames(value: any,  idCalendar: any){

   this.openSelect=false
   this.closeDropDown=true

   if(this.calendarsNamesSelectedArr.filter(nameSelected => nameSelected.idCalendar==idCalendar).length===0)
   this.calendarsNamesSelectedArr.push({'name':value, 'idCalendar': idCalendar.toString()})

   this.selectedIdCalendars.emit(this.calendarsNamesSelectedArr)




}


setTime( isOpenFrom:boolean, isOpenTo:boolean)
  { 
    
    if(isOpenTo)
     { 
      this.globalVariables.sethourToEvent(this.timeTo)
      isOpenTo=false}
    
     if(isOpenFrom)

     {
      this.arrHoursTo= recalculateArrHours(this.timeFrom, this.arrHours)
      this.timeTo= (addHourToStr(this.timeFrom,':', this.duration))
      this.globalVariables.sethourFromEvent(this.timeFrom)
      this.globalVariables.sethourToEvent(this.timeTo)
      isOpenFrom=false}

}

setAllDay(e:any,value:boolean){
   // if(e.target.checked)
   // {
     /* this.dateFrom=parseDate(this.dateDefault) + ' ' + this.startDay
      this.dateFrom= transfromStringtoDate(this.dateFrom)
      this.dateTo=parseDate(this.dateDefault) + ' ' + this.endDay
      this.dateTo= transfromStringtoDate(this.dateTo)
      this.timeFrom=this.startDay;
      this.timeTo= this.endDay;
      this.globalVariables.setdateFromEvent(transfromDateEN(this.dateFrom))
      this.globalVariables.setdateToEvent(transfromDateEN(this.dateTo))
      this.globalVariables.sethourFromEvent(this.timeFrom)
      this.globalVariables.sethourToEvent(this.timeTo)*/
  //  }
}
  
  

clickDeleteName(value: any){
    this.calendarsNamesSelectedArr= this.calendarsNamesSelectedArr.filter(names=>names!=value)
    this.selectedIdCalendars.emit(this.calendarsNamesSelectedArr)
}


createArrCalendarsName(){
    this.calendar.forEach(calendar => {
      const {idCalendar, name, shortName} = calendar;
      this.calendarsNamesArr.push({'name': (name? name:shortName), 'idCalendar': idCalendar}) 
    });

  
    return this.calendarsNamesArr;
    
}

createCalendarsNameArrClone(){
    this.calendarsNamesArr.forEach(calendar => {
      const {idCalendar, name, shortName} = calendar;
      this.calendarsNamesArrClone.push({'name': (name? name:shortName), 'idCalendar': idCalendar}) 
    });

    return this.calendarsNamesArrClone;
    
}

_handleTab(event: KeyboardEvent) {
    if (event.code === 'Tab') { // se pulso tab
         if (event.shiftKey) { // tambien se pulso conjuntamente shift
              document.getElementById('input3').focus();
            }
         }
}

clickDateSelected(event){
     if(this.isActiveTo){
        this.dateTo= transfromStringtoDate(event)
        this.globalVariables.setdateToEvent(transfromDateEN(this.dateTo))
        this.isActiveTo=false
      }
      
     if(this.isActiveFrom){
       this.dateFrom= transfromStringtoDate(event)
         this.globalVariables.setdateFromEvent(transfromDateEN(this.dateFrom))
       if (this.dateDefault!='')
         {
           this.dateTo= transfromStringtoDate(event)
           this.globalVariables.setdateToEvent(transfromDateEN(this.dateTo))
           this.dateDefault=''
         }
 
         this.isActiveFrom=false
      }              
}

  
getParams(event){
  this.objParams=event
  if(this.objParams.repeat)
  {
    this.repeat=true
    this.show=false
    this.dateTo=this.dateFrom
  }

}


goToRecurrence(){
  this.repeat=true

  if(this.show) this.show=false
  else this.show=true
}

changeRepeatCheck(){
  this.repeat=!this.repeat

  if(!this.repeat)
    this.show=false
  else this.show=!this.show

}

sendMessage(){

 
  document.addEventListener('click', function(e) {
    if(document.getElementById('share-1') !=null)
    {
      //console.log(e.target,'e target')
      if (document.getElementById('share').contains(e.target as Node)) {
        document.getElementById('share-1').style.display="block"
      } 
      else
        document.getElementById('share-1').style.display="none"
    }
  })

  document.addEventListener('click', function(e) {
    if(document.getElementById('share-1-mobile') !=null)
    {
      //console.log(e.target,'e target')
      if (document.getElementById('share-mobile').contains(e.target as Node)) {
        document.getElementById('share-1-mobile').style.display="block"
      } 
      else
        document.getElementById('share-1-mobile').style.display="none"
    }
  })


 // const data={'message':"hola",'phone':"34644074320"}
  //this.api.sendMessages(data).subscribe(res=>(
   // console.log(res,'enviado mensaje whatsapp')))
}

sendWhatsapp(){
  const htmlMessage="Tiene cita para revisión de su vehículo en el taller:" + " " + this.workPlaceName + ", " +"el día:" +" "+ this.dateFrom + " " + "a las" + " " + this.timeFrom + "hrs"
  this.utilsService.openModalShareEvents(ShareEventsComponent, this.shareContainerRef, htmlMessage, this.workPlaceName, this.phone).subscribe(res=>{
    if(res){
      switch(res["result"]){
        case 'OK':
          this.confirmWhatsapp=true
          break;
        case 'ERROR':
          break;
      }}
  })
}


saveNewEvent(){

  if(this.allDay){
      this.dateFrom = parseDate(this.dateDefault) + ' ' + this.startDay
      this.dateFrom = transfromStringtoDate(this.dateFrom)
      this.dateTo   = parseDate(this.dateDefault) + ' ' + this.endDay
      this.dateTo   = transfromStringtoDate(this.dateTo)
      this.timeFrom = this.startDay;
      this.timeTo   = this.endDay;
      this.globalVariables.setdateFromEvent(transfromDateEN(this.dateFrom))
      this.globalVariables.setdateToEvent(transfromDateEN(this.dateTo))
      this.globalVariables.sethourFromEvent(this.timeFrom)
      this.globalVariables.sethourToEvent(this.timeTo)
  }

  let dateTimeFrom = this.globalVariables.dateFromEvent + ' '+this.globalVariables.hourFromEvent 
  let dateTimeTo   = this.globalVariables.dateToEvent+' ' + this.globalVariables.hourToEvent
  var idCalendars: Array<any>=[]

  if(this.calendarsNamesSelectedArr && this.calendarsNamesSelectedArr.length>0){
       for (let i=0; i<this.calendarsNamesSelectedArr.length; i++)
        {
          idCalendars.push(this.calendarsNamesSelectedArr[i].idCalendar) 
        }

        this.data={
            'idCalendars':idCalendars,
            'title': this.title,
            'dateTimeFrom':dateTimeFrom,
            'dateTimeTo':dateTimeTo,
            'description':this.description,
            'repeat':this.objParams.repeat,
            'repeatType': this.objParams.type,
            'repeatAlways': this.objParams.repeatAlways,
            'repeatUntil': this.objParams.repeatUntil,
            'repeatEvery': this.objParams.repeatEvery,
            'repeatDuring': this.objParams.repeatDuring,
            'repeatWeeklyMonday': this.objParams.repeatWeeklyMonday,
            'repeatWeeklyTuesday': this.objParams.repeatWeeklyTuesday,
            'repeatWeeklyWednesday': this.objParams.repeatWeeklyWednesday,
            'repeatWeeklyThursday': this.objParams.repeatWeeklyThursday,
            'repeatWeeklyFriday': this.objParams.repeatWeeklyFriday,
            'repeatWeeklySaturday': this.objParams.repeatWeeklySaturday,
            'repeatWeeklySunday': this.objParams.repeatWeeklySunday,
            'repeatWeekdayPosition':this.objParams.repeatWeekdayPosition
           // 'repeatMonthDayPosition': this.objParams.repeatMonthDayPosition
        }

        this.validateForm(this.globalVariables.dateFromEvent, this.globalVariables.dateToEvent, this.title, this.calendarsNamesSelectedArr )
            
        if (this.message==''){
          //this.apiaddNewEvent(this.data)
          this.api.service('/addCalendarDetail','post',this.data).subscribe(res=>{
            if(res.msg='OK'){
              var calendars =[]
              res.calendars.forEach((calendar) => {
                const {idCalendar, name, shortName} = calendar;
                 calendars.push({ calendar :{
                  idCalendar:idCalendar, 
                  shortName: shortName,
                  name:name
                }
                });})

              this.data={'idCalendar':idCalendars,
              'idCalendarDetail':res.calendarDetail.idCalendarDetail,
              'title': res.calendarDetail.title,
              'dateFrom':res.calendarDetail.dateFrom,
              'dateTo':res.calendarDetail.dateTo, 
              'description':res.calendarDetail.description,
              'calendars': calendars,
              'dateFromModal': res.calendarDetail.dateFrom,
              'dateToModal': res.calendarDetail.dateTo,
              repeatParams: {
                repeat                :  res.calendarDetail.repeat,
                repeatAlways          :  res.calendarDetail.repeatAlways,
                repeatDuring          :  res.calendarDetail.repeatDuring,
                repeatEvery           :  res.calendarDetail.repeatEvery,
                repeatType            :  res.calendarDetail.repeatType,
                repeatUntil           :  res.calendarDetail.repeatUntil,
                repeatWeekdayPosition :  res.calendarDetail.repeatWeekdayPosition,
                repeatWeeklyFriday    :  res.calendarDetail.repeatWeeklyFriday,
                repeatWeeklyMonday    :  res.calendarDetail.repeatWeeklyMonday,
                repeatWeeklySaturday  :  res.calendarDetail.repeatWeeklySaturday,
                repeatWeeklySunday    :  res.calendarDetail.repeatWeeklySunday,
                repeatWeeklyThursday  :  res.calendarDetail.repeatWeeklyThursday,
                repeatWeeklyTuesday   :  res.calendarDetail.repeatWeeklyTuesday,
                repeatWeeklyWednesday :  res.calendarDetail.repeatWeeklyWednesday
               }  

              }
  
        
              this.submitModal.emit({ result: "aceptar", data: this.data});
            }
          })
        }

 }
}

updateEvent(){
  var idCalendars: Array<any>=[]
  if(this.allDay){
    this.dateFrom  = parseDate(this.dateDefault) + ' ' + this.startDay
    this.dateFrom  = transfromStringtoDate(this.dateFrom)
    this.dateTo    = parseDate(this.dateDefault) + ' ' + this.endDay
    this.dateTo    = transfromStringtoDate(this.dateTo)
    this.timeFrom  = this.startDay;
    this.timeTo    = this.endDay;
    this.globalVariables.setdateFromEvent(transfromDateEN(this.dateFrom))
    this.globalVariables.setdateToEvent(transfromDateEN(this.dateTo))
    this.globalVariables.sethourFromEvent(this.timeFrom)
    this.globalVariables.sethourToEvent(this.timeTo)
}

  let dateTimeFrom = this.globalVariables.dateFromEvent + ' '+this.globalVariables.hourFromEvent 
  let dateTimeTo   = this.globalVariables.dateToEvent+' ' + this.globalVariables.hourToEvent

  if(this.calendarsNamesSelectedArr.length)
    idCalendars=this.getIdCalendars(this.calendarsNamesSelectedArr)

  this.updateAssignedResources({
    'idCalendars':idCalendars,
    'title': this.title,
    'dateTimeFrom':dateTimeFrom,
    'dateTimeTo':dateTimeTo,
    'description':this.description,
    'repeat':this.objParams.repeat,
    'repeatType': this.objParams.type,
    'repeatAlways': this.objParams.repeatAlways,
    'repeatUntil': this.objParams.repeatUntil,
    'repeatEvery': this.objParams.repeatEvery,
    'repeatDuring': this.objParams.repeatDuring,
    'repeatWeeklyMonday': this.objParams.repeatWeeklyMonday,
    'repeatWeeklyTuesday': this.objParams.repeatWeeklyTuesday,
    'repeatWeeklyWednesday': this.objParams.repeatWeeklyWednesday,
    'repeatWeeklyThursday': this.objParams.repeatWeeklyThursday,
    'repeatWeeklyFriday': this.objParams.repeatWeeklyFriday,
    'repeatWeeklySaturday': this.objParams.repeatWeeklySaturday,
    'repeatWeeklySunday': this.objParams.repeatWeeklySunday,
    'repeatWeekdayPosition':this.objParams.repeatWeekdayPosition,
    'option': this.typeUpdate,
    'date' :  this.currentDateRepeat? parseDate(this.currentDateRepeat):''

  })


}


editEvent(){
  this.typeUpdate=''
  if(this.repeatParams.repeat)
    this.updateSeriesEvent()
  else
    this.updateEvent()

}

validateForm(dateFrom: any, dateTo: any, field:any, arr: Array<any>)
{
  let validateDateOK= validateDatesEN(dateFrom, dateTo,'-')
    if(!validateDateOK)
      this.message ='La fecha/ hora Desde no puede ser mayor que la fecha/hora Hasta'
  
    else {
      this.message=''
      let validateTitleOK= validateTitle(field)
        if (!validateTitleOK)
        { this.message='El campo Título no puede estar en blanco'}
        else{
          this.message=''
          let validateCalendarioOK= validateCalendario(arr)
            if(!validateCalendarioOK)
             {this.message='El campo Calendario no puede estar en blanco'}
              else{
                this.message=''
              }
        }
    }
}



updateAssignedResources(data: any){
  var result: any={}
  if(this.idDetail)
  {
    if (data)
    {
      //this.api.updateEvent(this.idDetail,data)
      this.api.service('/calendarDetail/'+this.idDetail,'put',data).subscribe(res=>{
        if(res.msg='OK')
          {   var calendars =[] 
              res.calendars.forEach((calendar) => {
              const {idCalendar, name, shortName} = calendar;
               calendars.push({ calendar :{
                idCalendar:idCalendar, 
                shortName: shortName,
                name:name
              }
              });})

             /* this.newEvents.push({'idCalendarDetail':Number(this.idDetail),
              'idCalendar': calendars,
              'title': this.title,
              'dateFrom': transfromDateTimeENT(this.dateFrom,this.timeFrom),
              'dateTo': transfromDateTimeENT(this.dateTo,this.timeTo),
              'description': this.description,
              })*/

              result= {'idCalendarDetail':Number(this.idDetail),
              'idCalendar': calendars,
              'title': this.title,
              'dateFrom': transfromDateTimeENT(this.dateFrom,this.timeFrom),
              'dateTo': transfromDateTimeENT(this.dateTo,this.timeTo),
              'description': this.description,
              'dateFromModal': res.calendarDetail.dateFrom,
              'dateToModal': res.calendarDetail.dateTo,
              'repeatParams': {
                repeat                :  res.calendarDetail.repeat,
                repeatAlways          :  res.calendarDetail.repeatAlways,
                repeatDuring          :  res.calendarDetail.repeatDuring,
                repeatEvery           :  res.calendarDetail.repeatEvery,
                repeatType            :  res.calendarDetail.repeatType,
                repeatUntil           :  res.calendarDetail.repeatUntil,
                repeatWeekdayPosition :  res.calendarDetail.repeatWeekdayPosition,
                repeatWeeklyFriday    :  res.calendarDetail.repeatWeeklyFriday,
                repeatWeeklyMonday    :  res.calendarDetail.repeatWeeklyMonday,
                repeatWeeklySaturday  :  res.calendarDetail.repeatWeeklySaturday,
                repeatWeeklySunday    :  res.calendarDetail.repeatWeeklySunday,
                repeatWeeklyThursday  :  res.calendarDetail.repeatWeeklyThursday,
                repeatWeeklyTuesday   :  res.calendarDetail.repeatWeeklyTuesday,
                repeatWeeklyWednesday :  res.calendarDetail.repeatWeeklyWednesday
               },
               'option' : this.typeUpdate
              }
      
            this.submitModal.emit({ result: "aceptar", data: result})        
          }
        })
    }
  }
} 

 
  deleteSeriesEvent(val?:boolean)
  { 
    const modalRefseries=this.utilsService.openModalSeriesEvents(SeriesEventsComponent,this.seriesContainerRef,'delete-series','Aplicar únicamente a este evento','Aplicar a todos los eventos de la serie.','Aplicar a este evento y a los siguientes.')
    modalRefseries.subscribe(res=>{
      if(res)
      { switch(res["result"]){
        case "delete-series":
          switch(res["type"]){
            case "allEvents":
              this.typeDelete='allEvents'
              break;
            case "onlyThisEvent":
              this.typeDelete='onlyThisEvent'
              break;
            case "thisEventAndLater":
              this.typeDelete='thisEventAndLater'
              break;
          }
          break;
        case "cancel":
          this.typeDelete=''
         break;
      }}
      if (this.typeDelete!='')
        {
          var data={
            option: this.typeDelete,
            date  : parseDate(this.currentDateRepeat)
          }
          //this.api.deleteSeriesEvent(this.idDetail,data)
          this.api.service('/calendarDetail/'+this.idDetail,'delete',data).subscribe(res=>{
            if(res.msg='OK')
                    { 
                        if(val)
                        {
                          this.globalVariables.setresouserselected(0)
                        } 

                    }
                      else 
                      {
                      /*console.log('error eliminando evento ')*/ }
                      this.deleteMeEvent.emit({ result: "eliminar", data: this.idDetail})
                    }
           )
        }
   
    })
   
  }

  updateSeriesEvent(val?:boolean)
  { 
    const modalRefseries=this.utilsService.openModalSeriesEvents(SeriesEventsComponent,this.seriesContainerRef,'update-series','Aplicar únicamente a este evento','Aplicar a todos los eventos de la serie.','Aplicar a este evento y a los siguientes.')
    modalRefseries.subscribe(res=>{
      if(res)
      { switch(res["result"]){
        case "update-series":
          switch(res["type"]){
            case "allEvents":
              this.typeUpdate='allEvents'
              break;
            case "onlyThisEvent":
              this.typeUpdate='onlyThisEvent'
              break;
            case "thisEventAndLater":
              this.typeUpdate='thisEventAndLater'
              break;
          }
          break;
        case "cancel":
          this.typeUpdate=''
         break;
      }
      if (this.typeUpdate)
       this.updateEvent()}
    })
   
  }

  deleteEvent(val?: boolean){
    this.typeDelete=''
    if(this.repeatParams.repeat)
    {
      this.deleteSeriesEvent(val)
    }
    else{
      const modalRefmessage=this.utilsService.openModalShowMessage(ShowMessageComponent,this.showContainerRef,'¿Estas seguro que desea eliminar el evento?', 'delete')
      modalRefmessage.subscribe(res=>{
      if(res){
        switch(res["result"]){
          case "eliminar":
            { 
              //this.api.deleteEvent(this.idDetail)
              this.api.service('/calendarDetail/'+ this.idDetail,'delete').subscribe(res=>{
                if(res.msg='OK')
                         { 
                            if(val)
                            {
                              this.globalVariables.setresouserselected(0)
                            } 
          
                         }
                          else 
                          { console.log('error eliminando evento ') }
                this.deleteMeEvent.emit({ result: "eliminar", data: this.idDetail})
              }
              )
              break;
            }
          case "cancelar":
           {
            this.closeMeEvent.emit()
            break;
              }
          }}})
      }
   
  }

 

  getIdCalendars(arrCalendars:Array<any>): Array<any>{
    var idCalendars: Array<any>=[]
      for (let i=0; i<arrCalendars.length; i++ )
      {
        idCalendars.push(Number(arrCalendars[i].idCalendar))
      }
    return idCalendars
   }

  closeEvent(event){
    this.closeMeEvent.emit()
  }

  changeTab(event: { index: number; }){
    this.tabIndex = event.index;
    switch (this.tabIndex){
      case 0:
        this.type="daily";
        break;
      case 1:
        this.type="weekly";
        break;
      case 2:
        this.type="monthly";
        break;
      case 3:
        this.type="annually"
        break;
      default:
        this.type="daily"
        break;

    }

  }


 
 ngOnChanges(changes: SimpleChanges){
    if(changes.message)
      {this.message=changes.message.currentValue}
    
  }


}


function validateTitle(field1?: any):boolean{
  
  if (field1=='' || field1==null)
    { 
      return false
    }
    else{
      return true
    }
}

function validateCalendario(field1?: Array<any>):boolean{
  
  if (field1.length==0)
    { 
      return false
    }
    else{
      return true
    }
}





