import { ApiService } from './api.service';
import { Injectable,ViewContainerRef } from '@angular/core';
//import { Resource } from './base/resource';
import { ModalService } from './modal-service/modal-service.service';






@Injectable()
export class UtilsService {


    constructor(
        api: ApiService,
        private modalService: ModalService,
     
    ) {
      //  super( api, 'utils');
    }

  

    openModalAddNewEvent(component: any, view: ViewContainerRef, title: any, description: any,startTimeWorkplace?: any, date?: any, end?: any, name?: any, resourceId?: any, arrNames?: Array<any> ,allDay?: any, idDetail?:any, resourceIds?:Array<any>, repeatParams?:any, currentDateRepeat?:any,extendedProps?:any,workPlaceName?: any, servicesIds?:Array<any>, customer?: any, taskIds?: any, workPlace?:any)  {
       /* const options = {
            // title: 'El título de la modal',
            type: 'full',
            size: 'large',
            hideHeader: true,
            // miAtributoExtra: 'valor'
        };*/
       return  this.modalService.openModal( view, component, title, description,startTimeWorkplace, date,end, name, resourceId, arrNames, allDay, idDetail, resourceIds, repeatParams, currentDateRepeat, extendedProps, workPlaceName, servicesIds, customer, taskIds, workPlace)
    

    }

    openModalAddNewEventAssociate(component: any, view: ViewContainerRef, title: any, description: any,startTimeWorkplace?: any, date?: any, end?: any, name?: any, resourceId?: any, arrNames?: Array<any> ,allDay?: any, idDetail?:any, resourceIds?:Array<any>, repeatParams?:any, currentDateRepeat?:any,extendedProps?:any,workPlaceName?: any)  {
      /* const options = {
           // title: 'El título de la modal',
           type: 'full',
           size: 'large',
           hideHeader: true,
           // miAtributoExtra: 'valor'
       };*/
      return  this.modalService.openModal( view, component, title, description,startTimeWorkplace, date,end, name, resourceId, arrNames, allDay, idDetail, resourceIds, repeatParams, currentDateRepeat, extendedProps, workPlaceName)
   

   }

    openModalShowMessage(component: any, showContainerRef: ViewContainerRef, message: string, action: string)  {
        /* const options = {
             // title: 'El título de la modal',
             type: 'full',
             size: 'large',
             hideHeader: true,
             // miAtributoExtra: 'valor'
         };*/
        return  this.modalService.openModalShowMessage( showContainerRef, component, message, action)
     
 
     }

     openModalSearchEvents(component: any, showContainerRef: ViewContainerRef, id: any, view)  {
    
        return  this.modalService.openModalSearchEvents( showContainerRef, component, id, view)
     
 
     }

     openModalSeriesEvents(component: any, seriesContainerRef: ViewContainerRef, action: any, messageA:any, messageB: any, messageC:any){
        return  this.modalService.openModalSeriesEvents( seriesContainerRef, component, action, messageA, messageB, messageC)

     }

     openModalShareEvents(component: any, shareEventsContainerRef: ViewContainerRef, htmlMessage: any, workPlaceName: string, phone:string){

      return this.modalService.openModalShareEvents(shareEventsContainerRef, component, htmlMessage, workPlaceName, phone)

     }

     openModalSearchCustomer(component: any, searchCustomerscomponentRef: ViewContainerRef, criteria?: any){

      return this.modalService.openModalSearchCustomers(searchCustomerscomponentRef, component, criteria)

     }

   

}

      export function cifrarXor(valor: any, clave: any) {
         var i = 0;
         var j = 0;
         var sCifrado = "";


         if (undefined == clave)
            clave = "";

         while (i < valor.length) {
            sCifrado += String.fromCharCode(valor.charCodeAt(i) ^ clave.charCodeAt(j));
            j = (j + 1) % clave.length;
            i++;
         }
         return sCifrado;
      }

      export function getAuthString(userName: string, password: string, domain: string, publicKey: string, separator: string) {
         const cad = cifrarXor(userName + separator + password + separator + domain + separator + dtos(), publicKey);
         const b64cad = btoa(cad);
         return b64cad;
      }

      export function dtos(fecha?: any) {
         if (undefined == fecha) {
            fecha = new Date();
         }
         var sAnio = lpad(fecha.getFullYear().toString(), 4, '0');
         var sMes = lpad((fecha.getMonth() + 1).toString(), 2, '0');
         var sDia = lpad(fecha.getDate().toString(), 2, '0');

         return sAnio + sMes + sDia;
      }

      export function lpad(s: any, l: any, r: any) {
         if (undefined == r) {
            r = " ";
         }
         s = repl(r, l) + s;
         return s.substr(s.length - l, l);
      }

      export function repl(s: any, n: any) {
         var r = '';
         while (n) {
            if (n & 1) {
               r += s;
            }
            s += s;
            n >>= 1;
         }
         return r;
      }



 
